/*Color cia termal*/
$primary:           #da861a;
$secondary:         #00abc8;
$white:             #ffffff;
$black:             #000000;
$primary-dark:      #001973;

$info:              #0fc8f2;
$success:           #1dbd45;
$warning:           #ffbd53;
$danger:            #e6442b;
