/*-- Use For Custom Styling --*/
.logo-default, .logo-scrolled {
    width: 153px;
}

@media screen and (max-width: 767px) {
    .btn-setting {
        font-size: 13px;
        padding: 8px 28px;
        margin: 20px;
    }
}


.success-contact {
    margin: 30px;
    background: #da861a;
    text-align: center;
    padding: 20px;
    color: white;
    font-size: 20px;
}

.error-contact {
    margin: 30px;
    background: #e6442b;
    text-align: center;
    padding: 20px;
    color: white;
    font-size: 20px;
}
